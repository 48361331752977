import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import emcc from "../images/Executive Coach - Angela Cooney - EMCC -accredited coach.svg";

import { StaticImage } from "gatsby-plugin-image";

const Footer = ({ className, ...props }) => {
  className = cx(
    "w-full px-8 py-4 text-white bg-teal-700 flex flex-col md:flex-row justify-between items-start md:items-center clear-both mt-12",
    className
  );
  const linkPadding = cx("inline-block relative z-0 p-4 -m-4 hover:underline");
  return (
    <>
      <div className={className} {...props}>
        <div className="flex-initial text-xl font-semibold">
          Angela Cooney
          <br />
          <span className="text-pink-600 ">Executive Coach</span>
          <br />

            <StaticImage
              src="../images/Executive Coach - Angela Cooney - EMCC -accredited coach.svg"
              alt="Angela Cooney - EMCC certified Executive Coach - Senior Practitioner - Dublin "
            /><br />
            <StaticImage
              src="../images/emcc-volunteer-banner-angela-cooney.jpg"
              alt="Angela Cooney - EMCC Volunteer - Ireland "
            />

        </div>
        <div>
          <ul className="flex flex-col md:flex-row text-sm -mx-3 font-medium">
            <li className="mx-3 p-2 whitespace-nowrap">
              <Link className={linkPadding} to="/">
                Home
              </Link>
            </li>
            <li className="mx-3 p-2 whitespace-nowrap ">
              <Link className={linkPadding} to="/about">
                About Me
              </Link>
            </li>
            <li className="mx-3 p-2 whitespace-nowrap">
              <Link className={linkPadding} to="/services">
                Services
              </Link>
            </li>
            <li className="mx-3 p-2 whitespace-nowrap">
              <Link className={linkPadding} to="/coaching">
                Coaching
              </Link>
            </li>
            <li className="mx-3 p-2 whitespace-nowrap">
              <Link className={linkPadding} to="/contact">
                Contact Me
              </Link>
            </li>
            <li className="mx-3 p-2 whitespace-nowrap">
              <Link className={linkPadding} to="/blog">
                Blog
              </Link>
            </li>
            <li className="mx-3 p-2 mt-1"> ©2021 AC Executive Coach</li>
          </ul>
        </div>
      </div>

      <div className="w-full px-8 py-2 text-white text-xs font-light bg-teal-900 flex flex-col sm:flex-row justify-between items-start md:items-center">
        <div>
          <strong className="sm:ml-0 ml-3 font-bold">Email: </strong>
          <a className={linkPadding} href="mailto:angela@acexecutivecoach.com">
            angela@acexecutivecoach.com
          </a>
        </div>
        <div>
          <Link to="/" className={linkPadding}>
            ACExecutiveCoach.com
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
